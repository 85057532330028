import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from '@environment/environment'
import {
    CaseById,
    CaseEventApiListResponse,
    CaseEventItem,
    CaseItem,
    CaseListApiResponse,
    CaseListResponse,
    GetCaseDetail,
    ParamCaseEvents,
    ParamCreateCaseEvent,
    ParamUpdateCase,
} from '@models/cases.model'
import { SortSearchAndPaginationModal } from '@models/common.model'
import filterParams from '@shared/utils/filter'
import { Observable, map } from 'rxjs'
import { CaseManagementAPIConstant } from 'src/app/constant/api.constants'

@Injectable({ providedIn: 'root' })
export class CaseAPIService {
    constructor(private http: HttpClient) {}

    getCases(param: SortSearchAndPaginationModal): Observable<CaseListResponse> {
        const { currentPage, pageSize, sortField, sortDir } = param.pagination || {}

        const { siteId, dateFrom, dateTo } = param.filter || {}

        const statusId = param.filter && param.filter.status ? this.mapStatusToEnum(param.filter.status) : ''

        const queryParams: Record<string, string> = {
            pageNumber: currentPage?.toString() || '0',
            pageSize: pageSize?.toString() || '5',
            sortDir: sortDir || 'DESC',
            sortField: sortField || 'createdAt',
            queryString: param.search || '',
            siteId: siteId?.toString() || '',
            dateFrom: dateFrom || '',
            dateTo: dateTo || '',
        }

        if (statusId) {
            queryParams['status'] = statusId // Use a string key for 'statuses'
        }

        const queryString = Object.entries(queryParams)
            .filter(([, value]) => value !== '')
            .map(([key, value]) => `${key}=${value}`)
            .join('&')

        return this.http
            .get<CaseListApiResponse>(`${environment.apiBaseUrl}${CaseManagementAPIConstant.CASES}?${queryString}`)
            .pipe(
                map((apiResponse: CaseListApiResponse) => {
                    const mappedData = apiResponse.items.map((item) => {
                        return {
                            ...item,
                            caseStatus: item.status,
                        }
                    })
                    return {
                        data: mappedData,
                        meta: {
                            itemCount: apiResponse.totalCount,
                            pageCount: apiResponse.totalPages,
                            currentPage: apiResponse.pageNumber,
                            pageSize: pageSize || 1,
                        },
                    }
                })
            )
    }

    getCasesByLoadId(loadId: number, pageSize: number, pageIndex: number): Observable<CaseListResponse> {
        const queryParams: Record<string, string> = {
            pageNumber: pageIndex.toString(),
            pageSize: pageSize.toString(),
            sortDir: 'DESC',
            sortField: 'createdAt',
            loadId: loadId.toString(),
        }

        const queryString = Object.entries(queryParams)
            .filter(([, value]) => value !== '')
            .map(([key, value]) => `${key}=${value}`)
            .join('&')

        return this.http
            .get<CaseListApiResponse>(`${environment.apiBaseUrl}${CaseManagementAPIConstant.CASES}?${queryString}`)
            .pipe(
                map((apiResponse: CaseListApiResponse) => {
                    const mappedData = apiResponse.items.map((item) => {
                        return {
                            ...item,
                            caseStatus: item.status,
                        }
                    })
                    return {
                        data: mappedData,
                        meta: {
                            itemCount: apiResponse.totalCount,
                            pageCount: apiResponse.totalPages,
                            currentPage: apiResponse.pageNumber,
                            pageSize: 1,
                        },
                    }
                })
            )
    }

    getCaseEvents(param: ParamCaseEvents): Observable<CaseEventApiListResponse> {
        const caseId = param.id
        const { currentPage, pageSize } = param.pagination || {}

        const queryParams: Record<string, string> = {
            pageNumber: currentPage?.toString() || '0',
            pageSize: pageSize?.toString() || '20',
            sortDir: param.filter?.sortDir || 'DESC',
        }

        const queryString = Object.entries(queryParams)
            .filter(([, value]) => value !== '')
            .map(([key, value]) => `${key}=${value}`)
            .join('&')

        return this.http
            .get<CaseEventApiListResponse>(
                environment.apiBaseUrl + CaseManagementAPIConstant.CASES + '/' + caseId + '/events' + `?${queryString}`
            )
            .pipe(
                map((apiResponse: CaseEventApiListResponse) => {
                    return {
                        ...apiResponse,
                    }
                })
            )
    }

    updateCase(param: ParamUpdateCase): Observable<boolean> {
        const payload = filterParams(param)
        return this.http.patch<boolean>(
            `${environment.apiBaseUrl}${CaseManagementAPIConstant.CASES}/${param.caseId}`,
            payload
        )
    }

    createCaseEvents(param: ParamCreateCaseEvent): Observable<CaseEventItem> {
        return this.http.post<CaseEventItem>(
            `${environment.apiBaseUrl}${CaseManagementAPIConstant.CASES}/${param.id}/events`,
            {
                content: param.content,
                type: param.eventType,
            }
        )
    }

    getCaseDetailById(param: CaseById): Observable<{ caseDetail: CaseItem }> {
        const sortDir = param?.filter?.sortDir || 'ASC'
        return this.http
            .get<CaseItem>(`${environment.apiBaseUrl}${CaseManagementAPIConstant.CASES}/${param.id}?${sortDir}`)
            .pipe(
                map((apiResponse: CaseItem) => {
                    return {
                        caseDetail: apiResponse,
                    }
                })
            )
    }

    private mapStatusToEnum(status: string): string {
        const statusMap: Record<string, string> = {
            Pending: 'PENDING',
            Investigation: 'INVESTIGATION',
            Resolved: 'RESOLVED',
        }

        return statusMap[status]
    }
}
