/** effect for user list api call */

import { Injectable } from '@angular/core'
import { SortSearchAndPaginationModal } from '@models/common.model'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, concatMap, map, mergeMap, of } from 'rxjs'
import {
    createCaseEventFailed,
    createCaseEventSuccess,
    createCaseEvent,
    getCaseDetailById,
    getCaseDetailByIdFail,
    getCaseDetailByIdSuccess,
    getCaseEventFail,
    getCaseEvents,
    getCaseEventsSuccess,
    loadCaseError,
    loadCaseSuccess,
    loadCaseWithSearch,
    loadCases,
    resetStateAfterOperation,
    resetStateSuccess,
    updateCase,
    updateCaseFailed,
    updateCaseSuccess,
} from '../actions/case.action'
import { CaseAPIService } from '@services/cases.service'
import { CaseById, ParamCaseEvents, ParamCreateCaseEvent, ParamUpdateCase } from '@models/cases.model'

@Injectable()
export class CaseAPIEffects {
    loadCases$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadCases),
            mergeMap((param: SortSearchAndPaginationModal) =>
                this.caseAPIService.getCases(param).pipe(
                    map((cases) => {
                        return loadCaseSuccess({ cases })
                    }),
                    catchError(async (error) => loadCaseError({ error }))
                )
            )
        )
    )

    loadCaseWithSearch$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadCaseWithSearch),
            mergeMap((param: any) => {
                return this.caseAPIService.getCases(param).pipe(
                    map((cases) => {
                        return loadCaseSuccess({ cases })
                    }),
                    catchError(async (error) => loadCaseError({ error }))
                )
            })
        )
    )

    getCaseDetailById$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getCaseDetailById),
            mergeMap((param: CaseById) =>
                this.caseAPIService.getCaseDetailById(param).pipe(
                    map((caseDetail) => {
                        return getCaseDetailByIdSuccess(caseDetail)
                    }),
                    catchError(async (error) => {
                        return getCaseDetailByIdFail({ error })
                    })
                )
            )
        )
    })

    updateCase$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateCase),
            mergeMap((param: ParamUpdateCase) =>
                this.caseAPIService.updateCase(param).pipe(
                    map((data) => updateCaseSuccess()),
                    catchError(async (error) => {
                        return updateCaseFailed({ error })
                    })
                )
            )
        )
    })

    getCaseEvents$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getCaseEvents),
            mergeMap((param: ParamCaseEvents) =>
                this.caseAPIService.getCaseEvents(param).pipe(
                    map((caseDetail) => {
                        console.log('effects')
                        console.log(caseDetail)
                        return getCaseEventsSuccess({ events: caseDetail }) // Remove & Action type
                    }),
                    catchError((error) => {
                        return of(getCaseEventFail({ error })) // Remove & Action type
                    })
                )
            )
        )
    })

    createCaseEvents$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createCaseEvent),
            mergeMap((param: ParamCreateCaseEvent) =>
                this.caseAPIService.createCaseEvents(param).pipe(
                    map((data) => {
                        console.log('i created the case event')
                        console.log(data)
                        return createCaseEventSuccess()
                    }),
                    catchError(async (error) => {
                        return createCaseEventFailed({ error })
                    })
                )
            )
        )
    })

    resetStateAfterOperation$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(resetStateAfterOperation),
            mergeMap(() => {
                return of(resetStateSuccess())
            })
        )
    })

    constructor(private actions$: Actions, private caseAPIService: CaseAPIService) {}
}
