import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatTableModule } from '@angular/material/table'
import { RouterModule, Routes } from '@angular/router'
import { AppPathLink } from './constant/router.constants'
import { AuthGuard } from './guard/auth.guard'
import { LayoutComponent } from './shared/components/layout/layout.component'
import { SharedModule } from './shared/shared.module'
import { ComingSoonComponent } from '@shared/components/coming-soon/coming-soon.component'
import { NotFoundComponent } from '@shared/components/not-found/not-found.component'
import { LoginComponent } from '@module/auth/login/login.component'

const routes: Routes = [
    {
        path: '',
        redirectTo: AppPathLink.LOGIN,
        pathMatch: 'full',
    },

    {
        path: AppPathLink.LOGIN,
        component: LoginComponent,
    },

    {
        path: AppPathLink.USER_MANAGEMENT,
        component: LayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./modules/user-management/user-management.module').then((m) => m.UserManagementModule),
    },
    {
        path: AppPathLink.SITE_MANAGEMENT,
        component: LayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./modules/site-management/site-management.module').then((m) => m.SiteManagementModule),
    },
    {
        path: AppPathLink.OPERATION_MANAGEMENT,
        component: LayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./modules/operation-management/operation-management.module').then(
                (m) => m.OperationManagementModule
            ),
    },
    {
        path: AppPathLink.ORGANISATION_MANAGEMENT,
        component: LayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./modules/organisation-management/organisation-management.module').then(
                (m) => m.OrganisationManagementModule
            ),
    },
    {
        path: AppPathLink.CONTRACT_MANAGEMENT,
        component: LayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./modules/contract-management/contract-management.module').then((m) => m.ContractManagementModule),
    },

    {
        path: AppPathLink.TRANSPORT_ORDER,
        component: LayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./modules/contract-management/contract-management.module').then((m) => m.ContractManagementModule),
    },

    {
        path: AppPathLink.TRANSPORT_PERMIT,
        component: LayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./modules/transport-permit/transport-permit.module').then((m) => m.TransportPermitModule),
    },
    {
        path: AppPathLink.COMING_SOON,
        children: [
            {
                path: 'fleet-mgt',
                component: ComingSoonComponent,
            },
            {
                path: 'setting',
                component: ComingSoonComponent,
            },
            { path: 'reporting', component: ComingSoonComponent },
            { path: 'setting', component: ComingSoonComponent },
        ],
    },
    {
        path: AppPathLink.NOT_FOUND,
        component: NotFoundComponent,
    },
    {
        path: '**',
        redirectTo: AppPathLink.NOT_FOUND,
    },
]

@NgModule({
    imports: [RouterModule.forRoot(routes), CommonModule, MatTableModule, SharedModule],
    exports: [RouterModule],
})
export class AppRoutingModule {}
